.footer {
    margin-top: 50px;
}
.footer-text {
    /* padding: 20px 0px 0px 0px !important; */
}

@media screen and (max-width: 768px){
    .footer-greeting {
        padding: 20px 0px 0px 0px !important;
    }
    .footer-text {
        text-align: center; 
    }
}

@media screen and (max-width: 992px){
    .footer-greeting {
        padding: 20px 0px 0px 0px !important;
    }
    .footer-text {
        font-size: 13px;
        margin-bottom: 0px;
    }
    .footer-column {
        padding-top: 0px !important;
    }
}