.email-link {
    color: #fff
}
.email-link a {
    color: #fff
}
.email-link a:visited {
    color: #fff
}
.email-link a:active {
    color: #fff
}

.header {
    height: 150;
    padding-top: 110px;
    padding-bottom: 40px;
}

.header-text {
    margin: 0px;
    text-align: bottom;
    color: #fff;
}

p.links-text {
    margin: 20px 0 0 0;
    padding: 0px;
    text-align: right;
}

.links-text {
    text-align: bottom;
    color: #fff;
    padding-right: 40px;
    height: 19.5px;
}

.links-text-right {
    text-align: bottom;
    color: #fff;
    height: 19.5px;
}

.logo {
    text-align: left
}

.header-flex {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
}


@media screen and (max-width: 768px){
    .header-text {
        text-align: center;
        padding-top: 20px;
    }
    .logo {
        text-align: center; 
        padding: 30px 0px 0px 0px !important;
    }
    p.links-text {
        position: relative;
        text-align: center;
        font-size: 11px;
    }
    .header-flex {
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 992px){
    .logo {
        font-size: 13px;
        padding: 30px 0px 0px 0px !important;
    }
}