.alert {
    text-align: center;
    padding: 10px;
    background: #79c879;
    color: #fff;
    margin-bottom: 10px;
    border-radius: 0px;
}

@media screen and (max-width: 575px){
    .services-images {
        margin-top: 20px !important;
    }
}

@media screen and (max-width: 992px){
    .float-right {
        float: none !important;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px !important;
    }
}

