body {
    background: #fff;
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif; 
}

.font-light {
    font-weight: 300;
}

.font-small {
    font-size: 11px;
}

.font-small-med {
    font-size: 12px;
}
.font-14 {
    font-size: 14px;
}

h2 {
    font-size: 1rem;
    font-weight: bolder;
    padding: 0;
    margin: 0;
    line-height: 1.5;
}