.form-text {
    min-width: 100%;
    width: 100%;
    margin: 0px 0px 10px 0px;
    padding: 7.5px 0px 7.5px 10px;
    color: #999999;
    font-size: 13px;
    font-weight: 300px;
    border:.5px solid #3d3f3d
}

.form-div {
    min-width: 100%;
    width: 100%;
    padding: 0px;
}

.form-btn {
    background-color: #3d3f3d;
    border: none;
    color: #fff;
    height: 30px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.item-grow {
    flex-grow:1;
}