.gallery_product_img
{
    border:0.5px solid #c5c5c5;
    padding: 10px;
}

.img-responsive { 
    width:100%;
}

.row-grid {
    padding: 50px 15px 0 15px;
}

.img-container {
    position: relative;
}

.img-container:hover .info-image {
    opacity: .5;
}

.img-container:hover .middle {
    opacity: 1;
}

.info-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    min-height: 100%;
    height: 100%;
    min-width: 100%;
    width: 100%;
    padding: 10px 25px 10px 10px;

  }

  .img-overlay {
    display:flex;
    opacity: 1;
    background: rgba(0, 0, 0, .7);
    padding: 0px;
    min-width: 100%;
    width: 100%;
    min-height: 100%;
    color: white;
    font-size: 16px;
    justify-content: center;
    align-items: center;
  }
  .img-text {
      position: absolute;
      vertical-align: middle;
      text-align: center;
  }


@media screen and (max-width: 992px){
    .img-overlay {
        font-size: 12px;
    }
}
